import { createContext, useContext } from 'react';

import { SearchConfiguration } from '@AuroraTypes';

interface SearchConfigurationInterface extends SearchConfiguration {
  showFullFormMobile?: boolean;
  showSearchButton: boolean;
  useClientSideNavigation?: boolean;
  hideTabList?: boolean;
  setHideTabList?: (shouldHideTabs: boolean) => void;
  isFlexibilityEnabled?: boolean;
}

export const SearchConfigurationContext = createContext<SearchConfigurationInterface>({
  showDestinationInput: false,
  showFullFormMobile: false,
  showSearchButton: true,
  showHotelOnlySearch: false,
  useClientSideNavigation: false,
  hideTabList: false,
  setHideTabList: () => {},
  isFlexibilityEnabled: true,
});

export const useSearchConfigurationContext = () => useContext(SearchConfigurationContext);
